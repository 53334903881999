import React, { useState, useEffect} from 'react';

//https://react-bootstrap.github.io/components/carousel/
import Carousel from 'react-bootstrap/Carousel'

const Carroucel = (props) => {

    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    useEffect(() => {
    }, []);

    return (
        <Carousel activeIndex={index} onSelect={handleSelect} indicators={true} autoPlay={true} 
        className="background-black-border-top-mobile-30 header-corousel">
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src="/static/img/carroucel/0-ONE.jpg"
                    alt="First slide"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src="/static/img/carroucel/1-ONE.jpg"
                    alt="Second slide"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src="/static/img/carroucel/2-ONE.jpg"
                    alt="Third slide"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src="/static/img/carroucel/3-ONE.jpg"
                    alt="Four slide"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src="/static/img/carroucel/4-ONE.jpg"
                    alt="Five slide"
                />
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src="/static/img/carroucel/5-ONE.jpg"
                    alt="Six slide"
                />
            </Carousel.Item>

        </Carousel>
    )
}

export default Carroucel