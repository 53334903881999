import React, { Component } from 'react';

import Carroucel from '../component/Carroucel'
import CardProduct from '../component/CardProduct'
import CarroucelProduct from '../component/CarroucelProduct'

class Home extends Component {

    render() {
        return (
            <>
                <Carroucel />
                <div className="container-custom separator" ></div>
                <section className="quickly-gates home-mv-wrap">
                    <div className="row no-margin-right-left">
                        <div className="col-12 col-md-4 col-lg-4 no-padding-right-left mouse-cursor">
                            <CardProduct src="/static/img/z1o.jpg" title="" boxShadow={true} />
                        </div>
                        <div className="col-12 col-md-4 col-lg-4 no-padding-right-left mouse-cursor">
                            <CardProduct src="/static/img/z2o.jpg" title="" boxShadow={true} />
                        </div>
                        <div className="col-12 col-md-4 col-lg-4 no-padding-right-left mouse-cursor">
                            <CardProduct src="/static/img/z3o.jpg" title="" boxShadow={true} />
                        </div>
                    </div>
                </section>
                <div className="container-custom separator"></div>
                <section className="quickly-gates">
                    <div className="row no-margin-right-left">
                        <div className="col-12 col-md-6 col-lg-6 no-padding-right-left mouse-cursor">
                            <CardProduct src="/static/img/card/Pre1.jpg"
                                srcVideo="https://www.youtube.com/embed/reQB27-SLk8?autoplay=1&mute=1" title="Z490 AOURUS XTREME WATERFORCE" />
                        </div>
                        <div className="col-12 col-md-6 col-lg-6 no-padding-right-left mouse-cursor">
                            <CardProduct src="/static/img/card/Pre2.jpg"
                                srcVideo="https://www.youtube.com/embed/xBJFn2oT5FU?autoplay=1&mute=1" title="G.SKILL TRIDENT Z ROYAL SERIES" />
                        </div>
                    </div>
                </section>
                
                <section className="carroucel-product pt-1 pb-2">
                    <CarroucelProduct />
                </section>
               
            </>
        );
    }
}

export default Home;