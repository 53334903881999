import React, { Component, createRef } from 'react';
import emailjs from 'emailjs-com';

const SERVICE_ID = "rma_onecompany";
const TEMPLATE_ID = "template_rm_m8gdu8o";
const USER_ID = "user_v2i6gQh8h1B3ohLWXn6Y9";

//probar esto para enviar email
//https://www.youtube.com/watch?v=pE-sp2A9j-c
//https://www.npmjs.com/package/nodemailer-react
class Rma extends Component {

    constructor(props) {
        super(props);
        this.handleOnSubmit = this.handleOnSubmit.bind(this)
        this.refInputNumeroModelo = createRef()
        this.refInputNombre = createRef()
        this.refInputCorreo = createRef()
        this.refInputDescription = createRef()
        this.refInputFechaCompra = createRef()
        this.refInputTelefono = createRef()
        this.refInputNumeroSerie = createRef()

        this.state = {
            isMailSent: false
        }
    }

    handleOnSubmit(event) {
        event.preventDefault()
        let nombre_cliente = this.refInputNombre.current.value
        let self = this
        let msg = " El usuario " + nombre_cliente + ", nos ha enviado el siguiente detalle  desde el formulario de solicitud RMA " +
            "Número de Modelo del Producto:  " + this.refInputNumeroModelo.current.value + " " +
            "Teléfono: " + this.refInputTelefono.current.value + " " +
            "Número de Serie: " + this.refInputNumeroSerie.current.value + " " +
            "Correo Electrónico: " + this.refInputCorreo.current.value + " " +
            "Nombre: " + this.refInputNombre.current.value + " " +
            "Fecha de compra: " + this.refInputFechaCompra.current.value + " " +
            "Detalle : " + this.refInputDescription.current.value + " "

        var templateParams = {
            number_model_product: this.refInputNumeroModelo.current.value,
            to_name: nombre_cliente, //nombre de quien recibe el correo
            from_name: nombre_cliente,  //nombre de quien envia
            message: msg,
            reply_to: this.refInputCorreo.current.value,  //correo del cliente
        };

        emailjs.send(SERVICE_ID, TEMPLATE_ID, templateParams, USER_ID).then(
            function (response) {
                if (response.status === 200 && response.text === 'OK')
                    self.setState({ isMailSent: true })
                console.log(response.status, response.text);
            },
            function (err) {
                console.log(err);
            }
        );
    }

    render = () => {
        //const { menu } = this.props;
        return <div className="container mt-8 mb-8">
            <section className="perfil pt-3 text-white">
                <div className="row pb-3" >
                    <div className="col-12 col-md-12" >
                        <h3 className="heading text-purple text-left">Formulario de Solicitud RMA</h3>
                    </div>
                </div>

                {!this.state.isMailSent ?
                    <form id="formRma" onSubmit={this.handleOnSubmit}>
                        <div className="row pb-3" >
                            <div className="col-12 col-md-6 form-group" >
                                <label htmlFor="numero_serie" className="col-form-label">Número de Serie</label>
                                <input name="numero_serie" placeholder="Número de Serie" type="text" className="form-control"
                                    ref={this.refInputNumeroSerie} />
                            </div>

                            <div className="col-12 col-md-6 form-group" >
                                <label htmlFor="numero_modelo" className="col-form-label">Código del Producto</label>
                                <input name="numero_modelo" placeholder="Número de Modelo del Producto" type="text"
                                    className="form-control" ref={this.refInputNumeroModelo} />
                            </div>
                        </div>

                        <div className="row pt-4" >
                            <div className="col-12 form-group" >
                                <span>Datos del comprador</span>
                            </div>
                        </div>

                        <div className="row pb-3" >
                            <div className="col-12 col-md-6 form-group" >
                                <label htmlFor="nombre" className="col-form-label">Nombre Completo</label>
                                <input name="nombre" placeholder="Nombre" type="text" className="form-control"
                                    ref={this.refInputNombre} />
                            </div>

                            <div className="col-12 col-md-6 form-group" >
                                <label htmlFor="email" className="col-form-label">Correo Electrónico</label>
                                <input name="email" placeholder="Correo Electrónico" type="email" className="form-control"
                                    ref={this.refInputCorreo} />
                            </div>
                        </div>

                        <div className="row pb-3" >
                            <div className="col-12 col-md-6 form-group" >
                                <label htmlFor="telefono" className="col-form-label">Teléfono</label>
                                <input name="telefono" placeholder="Teléfono" type="text" className="form-control"
                                    ref={this.refInputTelefono} />
                            </div>
                            <div className="col-12 col-md-6 form-group" >
                                <label htmlFor="fecha_compra" className="col-form-label">Fecha de Compra</label>
                                <input name="fecha_compra" placeholder="Fecha de Compra" type="text" className="form-control"
                                    ref={this.refInputFechaCompra} />
                            </div>
                        </div>

                        <div className="row pb-3" >
                            <div className="col-12 col-md-12 form-group" >
                                <label htmlFor="descripcion" className="col-form-label">Descripción del RMA</label>
                                <textarea className="form-control" name="descripcion" rows="3" placeholder="Descripción"
                                    ref={this.refInputDescription}></textarea>
                            </div>
                        </div>

                        <div className="row pb-3" >
                            <div className="col-12 col-md-12 form-group" >
                                <button type="submit" className="btn btn-dark mb-2 float-right">Enviar</button>
                            </div>
                        </div>
                    </form>
                    :
                    <div className="w-100 text-center pt-5 pt-5">
                        <img src="static/img/sent_message.png" alt='sent message' />
                    </div>
                }
                <div className="row pb-3" >
                    <div className="col-12 col-md-12" >
                        <p>Cada uno de lo equipos tiene su propio número de serie, por favor asegurese de copiar el número correcto,
                            nosotros recibiremos el número RMA y le respondemoremos lo mas antes posible con las instrucciones a seguir.</p>
                    </div>
                </div>
            </section>
        </div>
    }
}

export default Rma;