import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Footer extends Component {

  render = () => {
    return (
      <footer className="footer">
        <div className="container-fluid  pt-2">
          <div className="container">
            <div className="row ">
              <div className="col-12 col-md-12  text-left">
                <div className="row">
                  <div className="col">
                    <h5 className="title text-left">Sobre Nosotros</h5>
                    <ul>
                      <li>
                        <Link to={{ pathname: "/perfil" }} className="" >Perfíl</Link>
                      </li>
                      <li>
                        <Link to={{ pathname: "/socios" }} className="" >Socios</Link>
                      </li>
                      <li>
                        <Link to={{ pathname: "/contactanos" }} className="" >Contáctanos</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="col">
                    <h5 className="title text-left">Comunidad</h5>
                    <ul>
                      <li><Link to={{ pathname: "/ultimas-noticias" }} draggable="false" >Últimas Noticias</Link></li>
                      <li><Link to={{ pathname: "/review" }} draggable="false" >Vídeos</Link></li>
                    </ul>
                  </div>
                  <div className="col">
                    <h5 className="title text-left">Apoyo</h5>
                    <ul>
                      <li>
                        <Link to={{ pathname: "/infografia" }} draggable="false" >Infografías</Link>
                      </li>
                    </ul>
                  </div>


                  <div className="col">
                    <h5 className="title text-left">Oficina</h5>
                    <ul>
                      <li>
                        <FontAwesomeIcon icon={'phone'} size="1x" className="mr-2" />
                        <a href="tel:4444-547" className="mr-2">4756758</a>
                      </li>
                      <li>
                        <FontAwesomeIcon icon={'mobile-alt'} size="1x" className="mr-2" />
                        <a href="tel:765-62214" className="mr-2">765-62214</a></li>
                      <li className="truncate"><a href="mailto:ventas03@onecompany.com.bo">
                        <FontAwesomeIcon icon={'envelope'} size="1x" className="mr-2" />info@onecompany.com.bo
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="col">
                    <h5 className="title text-left">Siguenos</h5>
                    <ul className="follow" >
                      <li>
                        <Link to={{ pathname: "https://www.facebook.com/Onecompany.empresa" }} className="icon" target="_blank" draggable="false">
                          <FontAwesomeIcon icon={['fab', 'facebook']} size="1x" className="icon-small" />
                        </Link>
                      </li>
                      <li>
                        <Link to={{ pathname: "https://www.facebook.com/Onecompany.empresa" }} className="icon" target="_blank" draggable="false">
                          <FontAwesomeIcon icon={['fab', 'youtube']} size="1x" className="icon-small" />
                        </Link>
                      </li>
                      <li>
                        <Link to={{ pathname: "https://www.facebook.com/Onecompany.empresa" }} className="icon" target="_blank" draggable="false">
                          <FontAwesomeIcon icon={['fab', 'twitter']} size="1x" className="icon-small" />
                        </Link>
                      </li>
                      <li>
                        <Link to={{ pathname: "/contactanos" }} className="icon" draggable="false">
                          <FontAwesomeIcon icon={'envelope'} size="1x" className="icon-small" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-12 ">
                <div className="footer-copy-right  pb-3">
                  <small className="" style={{ fontSize: 14, color: '#666' }}>© onecompany.com.bo {(new Date()).getFullYear()}. Todos los derechos reservados</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer;