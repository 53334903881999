import React, { Component } from 'react';

class Perfil extends Component {

    componentDidMount() { }

    render = () => {
       // const { menu } = this.props;
        return <div className="container mt-8 mb-8">
            <section className="perfil pt-3">
                <div className="row text-white our-company"
                    style={{ background: 'url(/static/img/product_bg.jpg) repeat-x', fontWeight: 600 }}>
                    <div className="col-12 col-md-6 align-self-center p-5">
                        <h2 className="title" >La Cultura de la Compañia</h2>
                    </div>

                    <div className="col-12 col-md-6 align-self-center pt-5 pb-5 pl-0 pr-0">
                        <div className="pl-5 pr-5 text text-justify" >
                            Onecompany es una empresa boliviana, que se dedica a la importación y 
                            comercialización de componentes y 
                            accesorios de computación, de las mejores marcas del mundo.
                            Somos un equipo de personas apasionados por el trabajo cuyo objetivo es 
                            satisfacer las necesidades de los clientes.
                        </div>
                    </div>
                </div>
                <div className="row text-white" >
                    <div className="col-12 col-md-4 p-4 pt-5 our-company"
                        style={{ background: 'url(/static/img/product_bg.jpg) repeat-x', fontWeight: 600 }}>
                        <h2 className="text-center title">Misión</h2>
                        <div className="text-justify" style={{minHeight: 220}}>
                            Proveer productos y servicios competitivos en la comercialización de las mejores marcas y
                            reconocidas del mundo. Contribuyendo en la rentabilidad y desarrollo de nuestros clientes con un
                            trabajo eficiente, confiable y seguro.
                        </div>
                        <img src="/static/img/target.png" width="128" className="float-right pt-5" alt="mision logo"/>
                    </div>
                    <div className="col-12 col-md-4 p-4 pt-5 our-company"
                        style={{ background: 'url(/static/img/product_bg.jpg) repeat-x', fontWeight: 600 }}>
                        <h2 className="text-center title ">Visión</h2>
                        <div className=" text-justify" style={{minHeight: 220}}>
                            Ser una empresa líder y referencia en el mercado nacional en la comercialización de productos tecnológicos,
                            ofreciendo a nuestros clientes una gama de productos y brindar un asesoramiento técnico logrando una completa
                            satisfacción de los clientes basado en principios y valores.
                        </div>
                        <img src="/static/img/binoculars.png" width="128" className="float-right pt-5 mt-4" alt="binoculares"/>
                    </div>
                    <div className="col-12 col-md-4 p-4 pt-5 our-company"
                        style={{ background: 'url(/static/img/product_bg.jpg) repeat-x', fontWeight: 600 }}>
                        <h2 className="text-center title">Principios y Valores</h2>
                        <div className=" text-justify" style={{minHeight: 220}}>
                            <ul>
                                <li>Respeto</li>
                                <li>Responsabilidad</li>
                                <li>Trabajo en equipo</li>
                                <li>Efectividad</li>
                                <li>Perseverancia</li>
                                <li>Compromiso</li>
                                <li>Honestidad</li>
                                <li>Tolerancia</li>
                                <li>Amistad</li>
                            </ul>
                        </div>
                        <img src="/static/img/plant-tree.png" width="128" className="float-right pt-5"  alt="arbol" />
                    </div>
                </div>
            </section>
        </div>
            ;
    }
}

export default Perfil;