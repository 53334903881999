import React, { Component } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../static/css/GridGalery.css';

class Infografia extends Component {

    constructor() {
        super();

        this.downloadImage = this.downloadImage.bind(this)
    }
    componentDidMount() {
    }

    downloadImage(event) {
        event.preventDefault()
        let node = event.target

        if (node.nodeName === 'svg' || node.nodeName === 'path') {
            let link = event.target.closest("a")
            let img = link.firstElementChild

            link.setAttribute('href', img.getAttribute("src"));
            link.setAttribute('download', img.getAttribute("name"));
            link.click()

            link.setAttribute('href', '#');
            link.removeAttribute('download');
        }
    }

    render = () => {
        //const { menu } = this.props;
        return <div className="container mt-8 mb-8">
            <section className="perfil pt-3 text-white">
                <div className="row">
                    <div className="col-12 col-md-4 col-lg-4 pb-3">
                        <a className="" href="#"  >
                            <img className="grid-gallery__image img-fluid rounded" src="/static/img/infografia/Monitor-Family-One-Company.jpg" name="Monitor-Family-One-Company.jpg" alt="Familia de monitores"/>
                            <FontAwesomeIcon icon={'arrow-down'} size="1x" className="bottom-infografia mouse-cursor btn-dark" onClick={this.downloadImage} />
                        </a>
                    </div>

                    <div className="col-12 col-md-4 col-lg-4 pb-3">
                        <a className="" href="#">
                            <img className="grid-gallery__image" src="/static/img/infografia/Monitores-Flyer-fb.jpg" name="Monitores-Flyer-fb.jpg" alt="monitores flyer"/>
                            <FontAwesomeIcon icon={'arrow-down'} size="1x" className="bottom-infografia mouse-cursor btn-dark" onClick={this.downloadImage} />
                        </a>
                    </div>

                    <div className="col-12 col-md-4 col-lg-4 pb-3">
                        <a className="" href="#">
                            <img className="grid-gallery__image img-fluid rounded" src="/static/img/infografia/Background template_03.jpg" name="Background_template_03.jpg" alt="background template"/>
                            <FontAwesomeIcon icon={'arrow-down'} size="1x" className="bottom-infografia mouse-cursor btn-dark" onClick={this.downloadImage} />
                        </a>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-4 col-lg-4 pb-3">
                        <a className="grid-gallery__item" href="#">
                            <img className="grid-gallery__image img-fluid rounded" src="/static/img/infografia/Post-TRX40.jpg" name="Post-TRX40.jpg" alt="trx40"/>
                            <FontAwesomeIcon icon={'arrow-down'} size="1x" className="bottom-infografia mouse-cursor btn-dark" onClick={this.downloadImage} />
                        </a>
                    </div>

                    <div className="col-12 col-md-4 col-lg-4 pb-3">
                        <a className="grid-gallery__item" href="#">
                            <img className="grid-gallery__image img-fluid rounded" src="/static/img/infografia/Post-z490-One-Company.jpg" name="Post-z490-One-Company.jpg" alt="z490"/>
                            <FontAwesomeIcon icon={'arrow-down'} size="1x" className="bottom-infografia mouse-cursor btn-dark" onClick={this.downloadImage} />
                        </a>
                    </div>


                    <div className="col-12 col-md-4 col-lg-4 pb-3">
                        <a className="grid-gallery__item" href="#">
                            <img className="grid-gallery__image img-fluid rounded" src="/static/img/infografia/B550-AORUS-ELITE-post.jpg" name="B550-AORUS-ELITE-post.jpg" alt="b550-aorus-elite"/>
                            <FontAwesomeIcon icon={'arrow-down'} size="1x" className="bottom-infografia mouse-cursor btn-dark" onClick={this.downloadImage} />
                        </a>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 col-md-4 col-lg-4 pb-3">
                        <a className="grid-gallery__item" href="#">
                            <img className="grid-gallery__image img-fluid rounded" src="/static/img/infografia/B550-Post-1200x1200.jpg" name="B550-Post-1200x1200.jpg" alt="b550 post" />
                            <FontAwesomeIcon icon={'arrow-down'} size="1x" className="bottom-infografia mouse-cursor btn-dark" onClick={this.downloadImage} />
                        </a>
                    </div>

                    <div className="col-12 col-md-4 col-lg-4 pb-3">
                        <a className="grid-gallery__item" href="#">
                            <img className="grid-gallery__image img-fluid rounded" src="/static/img/infografia/B550M-DS3H-post.jpg" name="B550M-DS3H-post.jpg" alt="b550m-ds3h" />
                            <FontAwesomeIcon icon={'arrow-down'} size="1x" className="bottom-infografia mouse-cursor btn-dark" onClick={this.downloadImage} />
                        </a>
                    </div>

                    <div className="col-12 col-md-4 col-lg-4 pb-3">
                        <a className="grid-gallery__item" href="#">
                            <img className="grid-gallery__image img-fluid rounded" src="/static/img/infografia/B550-2000x540.jpg" name="B550-2000x540.jpg" alt="b550-full-image"/>
                            <FontAwesomeIcon icon={'arrow-down'} size="1x" className="bottom-infografia mouse-cursor btn-dark" onClick={this.downloadImage} />
                        </a>
                    </div>
                </div>

            </section>
        </div>
    }
}

export default Infografia;