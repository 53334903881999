import React, { Component, createRef } from 'react';
import emailjs from 'emailjs-com';

const SERVICE_ID = "contactanos_onecompany";
const TEMPLATE_ID = "template_con9530qag";
const USER_ID = "user_v2i6gQh8h1B3ohLWXn6Y9";

class Contactanos extends Component {

    constructor(props) {
        super(props);
        this.handleOnSubmit = this.handleOnSubmit.bind(this)
        this.refInputEmail = createRef()
        this.refInputProposito = createRef()
        this.refInputMensaje = createRef()

        this.state = {
            isMailSent: false
        }
    }

    handleOnSubmit(event) {
        event.preventDefault()
        let nombre_cliente = this.refInputEmail.current.value
        let msg = this.refInputMensaje.current.value
        let self = this

        var templateParams = {
            proposito: this.refInputProposito.current.value,
            to_name: nombre_cliente, //nombre de quien recibe el correo
            from_name: nombre_cliente,  //nombre de quien envia
            message: msg,
            reply_to: this.refInputEmail.current.value,  //correo del cliente
        };

        emailjs.send(SERVICE_ID, TEMPLATE_ID, templateParams, USER_ID).then(
            function (response) {
                if(response.status === 200 && response.text === 'OK')
                    self.setState({isMailSent: true})
                console.log(response.status, response.text);
            },
            function (err) {
                console.log(err);
            }
        );
    }

    render = () => {
        //const { menu } = this.props;
        return <div className="container mt-8 mb-8">
            <section className="perfil pt-3 text-white">
                <div className="row pb-3" style={{ borderBottom: 'solid 1px #3a3a3a' }}>
                    <div id="map" className="col-12 col-md-6" style={{ backgroundImage: 'url(https://f.cl.ly/items/0g0l283X3h1T3H270V0A/netherlands.png)' }}>
                        <h3 className="heading">Donde nos puedes encontrar</h3>
                        <div className="map-locator">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1185.731804220657!2d-66.1141646707786!3d-17.44840241985551!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x93e371c67ae57719%3A0xe9ceb8746e69ba71!2sOne%20Company!5e1!3m2!1ses-419!2sbo!4v1603596485362!5m2!1ses-419!2sbo"
                                width="100%" height="380" frameBorder="0" style={{ border: 0 }} allowFullScreen="" aria-hidden="false" tabIndex="0" title="localizacion google map"></iframe>
                        </div>
                    </div>

                    <div className="col-12 col-md-6">
                        <h3 className="heading">Contactanos</h3>
                        { !this.state.isMailSent ?
                            <form id="formRma" onSubmit={this.handleOnSubmit}>
                                <div className="form-group">
                                    <label htmlFor="email" className="col-form-label">Correo Electrónico</label>
                                    <input name="email" placeholder="Correo Electronico" type="email" className="form-control" ref={this.refInputEmail} />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="proposito" className="col-form-label">Proposito</label>
                                    <input name="proposito" placeholder="Proposito" type="text" className="form-control" ref={this.refInputProposito} />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="mensaje" className="col-form-label">Mensaje</label>
                                    <textarea name="mensaje" placeholder="Mensaje" rows="4" className="form-control" ref={this.refInputMensaje}></textarea>
                                </div>

                                <div className="form-group text-right">
                                    <input placeholder="Enviar" type="submit" className="btn btn-dark" />
                                </div>
                            </form>
                            :
                            <div className="w-100 text-center pt-5">
                                <img src="static/img/sent_message.png" alt='sent message' />
                            </div>
                            
                        }
                    </div>
                </div>

                <div className="row">
                    <div className="col pt-4 item">
                        <h4 className="title">Comercialización</h4>
                        <a href="mailto:ejec.comercial1@onecompany.com.bo" className="link truncate">
                            <span className="text-white">EJECUTIVO DE VENTAS 1 </span><br />  <strong className=" ml-3 text-white">Correo: </strong> <span>ejec.comercial1@onecompany.com.bo</span>
                        </a>
                        <br />
                        <a href="mailto:ejec.comercial2@onecompany.com.bo" className="link truncate">
                            <span className="text-white">EJECUTIVO DE VENTAS 2 </span><br />  <strong className=" ml-3 text-white">Correo: </strong> <span>ejec.comercial2@onecompany.com.bo</span>
                        </a>
                    </div>
                    <div className="col pt-4 item">
                        <h4 className="title">Soporte</h4>
                        <a href="mailto:suport@onecompany.com.bo" className="link truncate">
                            <strong className="text-white">Correo: </strong> suport@onecompany.com.bo
                        </a>
                    </div>
                </div>
            </section>
        </div>
    }
}

export default Contactanos;