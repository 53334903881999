import React, { Component } from 'react';

class Page404 extends Component {

    constructor(props) {
        super(props);
       
        this.state = {};
    }

    componentDidMount() {
        //if (window.location.pathname === Links[6].url) {  //'/declaracion-jurada-edit'
        //    window.location.reload();
        //}
    }

    render = () => {
        return <div id="services" className="" >
            Pagina 404
        </div>

    }
}

export default Page404;