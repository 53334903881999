import React, { Component } from 'react';

class Review extends Component {

    componentDidMount() {
    }

    render = () => {
        //const { menu } = this.props;
        return <div className="container mt-8 mb-8">

            <section className="perfil pt-3 text-white">
                <div className="row pb-3" >
                    <div className="col-12 col-md-12" >
                        <h3 className="heading text-purple text-left">Review de Productos</h3>
                    </div>
                </div>
            </section>

            <section className="quickly-gates">

                <div className="row no-margin-right-left">
                    <div className="col-12 col-md-6 col-lg-6 ">
                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/aFSkt92TOXA?autoplay=0&mute=1" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen title="aFSkt92TOXA"></iframe>
                        <p className="text-white">¡PONIENDO A PRUEBA LA RTX 3080! ¿Es tan BUENA como dicen?</p>
                    </div>

                    <div className="col-12 col-md-6 col-lg-6 ">
                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/9udxsw5RWGs?autoplay=0&mute=1" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen title="9udxsw5RWGs"></iframe>
                        <p className="text-white">RTX 3080 EVGA XC3 (Pruebas y Comparativas - 4K Raytracing - Graficos Ultra) Vs RTX 2080 y RTX 2080Ti</p>
                    </div>
                </div>
            </section>

            <section className="quickly-gates">
                <div className="row no-margin-right-left">
                    <div className="col-12 col-md-6 col-lg-6 ">
                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/kBZSvd4B3RA?autoplay=0&mute=1" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen title="kBZSvd4B3RA"></iframe>
                        <p className="text-white">Z490 Aorus Master #review y unboxing en español |GameIt ES</p>
                    </div>

                    <div className="col-12 col-md-6 col-lg-6 ">
                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/V94dTPQEPxY?autoplay=0&mute=1" frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowfullscreen title="V94dTPQEPxY"></iframe>
                        <p className="text-white">ESTRENO MUNDIAL* B550 LO QUE NADIE TE CUENTA <span role="img" aria-label="corona">😱</span> MEJOR QUE X570? B550 AORUS PRO ANALISIS EN ESPAÑOL</p>
                    </div>
                </div>
            </section>

        </div>
    }
}

export default Review;