import React, { Component } from 'react';
import '../Components/FontawsomeIcons'
import PropTypes from 'prop-types';
// Components
import Header from './../Components/Global/Header';
import Content from './../Components/Global/Content';
import Footer from './../Components/Global/Footer';

import 'bootstrap/dist/css/bootstrap.min.css';


class App extends Component {
    static propTypes = {
        children: PropTypes.object.isRequired
    };

    render() {
        const {children} = this.props;
        return (
            <div className="container-fluid no-padding-right-left">
                {/*<Header title="ATM" menu={Menu} user={this.props.user}></Header>*/}
                <Header title="One Company" user={this.props.user}></Header>
                <Content body={children} />
                <Footer></Footer>
            </div>
        );
    }
}

//export default WithAuth(App);
export default App;