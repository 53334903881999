import React, { Component, createRef } from 'react';
import WhereToBuyJson from '../static/data/WhereToBuyJson.json'
import CardEmpresa from '../component/CardEmpresa'

class WhereToBuy extends Component {

    constructor() {
        super();
        this.handleClickDepartamento = this.handleClickDepartamento.bind(this)

        this.refSlcDepartamentos = createRef()

        let deparments = WhereToBuyJson.Departamentos.map((department, index) => {
            return <option key={department.id} value={department.id}>
                {department.name}
            </option>
        })

        this.state = {
            optionsDepartament: deparments,
            departamentos: WhereToBuyJson.Departamentos,
            empresas: ""
        }
    }

    componentDidMount() {
        this.setDataEmpresa ("CBBA")
    }

    handleClickDepartamento(event, code_department) {
        event.preventDefault()
        let cards = document.getElementsByClassName("card-select")  //[0].classList.remove("card-select-selected")
    
        for (let index = 0; index < cards.length; index++) {
            cards[index].classList.remove("card-select-selected")
            cards[index].classList.add("card-select-no-selected")
        }

        this.setDataEmpresa (code_department)
        event.target.classList.remove("card-select-no-selected")
        event.target.classList.add("card-select-selected")
    }

    setDataEmpresa (code_department) {
        let depart = WhereToBuyJson.Departamentos.filter(empresa => empresa.code === code_department)
        let empresas = WhereToBuyJson.Empresas.filter(empresa => empresa.id_departamento === depart[0].id)
        let card_empresa = empresas.map((empresa, index) => {
            return <CardEmpresa key={empresa.id} empresa={empresa}/>
        })
        this.setState({empresas: card_empresa})
    }

    render = () => {
        const { departamentos } = this.state
        //const { menu } = this.props;
        return <div className="container mt-8 mb-8">
            <section className="perfil pt-3 text-white">
                <div className="row pb-3"  >
                    <div className="col-12" style={{ borderBottom: '1px solid rgb(58, 58, 58)' }}>
                        <h3 className="heading text-left">Encuentra la tienda mas cerca de tí</h3>
                    </div>
                </div>

                <div className="row pt-3 pb-3" >
                    <div className="col-12 col-md-2 card-select card-select-selected" onClick={e => this.handleClickDepartamento(e, departamentos[0].code)}>
                        {departamentos[0].name}
                    </div>

                    <div className="col-12 col-md-2 card-select card-select-no-selected" onClick={e => this.handleClickDepartamento(e, departamentos[1].code)} >
                        {departamentos[1].name}
                    </div>

                    <div className="col-12 col-md-2 card-select card-select-no-selected" onClick={e => this.handleClickDepartamento(e, departamentos[2].code)} >
                        {departamentos[2].name}
                    </div>

                    <div className="col-12 col-md-2 card-select card-select-no-selected" onClick={e => this.handleClickDepartamento(e, departamentos[3].code)} >
                        {departamentos[3].name}
                    </div>

                    <div className="col-12 col-md-2 card-select card-select-no-selected" onClick={e => this.handleClickDepartamento(e, departamentos[4].code)}>
                        {departamentos[4].name}
                    </div>
                </div>

                <div className="row pt-3 pb-3" >
                    {this.state.empresas}
                </div>
            </section>
        </div>
    }
}

export default WhereToBuy;