import React, {  } from 'react';

//https://react-bootstrap.github.io/components/carousel/
//import Carousel from 'react-bootstrap/Carousel'

const CardEmpresa = (props) => {

    let {empresa} = props
    return (
        <div className="col-12 col-md-6 col-lg-4" >
            <div className="card mt-4">
                <div className="card-header text-center">
                    <h3 className="text-dark truncate">{empresa.name}</h3>
                    <a href={empresa.pagina_web ? empresa.pagina_web : "#"} className="badge badge-pill badge-danger ml-2" target="_blank" rel="noopener noreferrer">
                        {empresa.logo !== undefined && empresa.logo !== "" ? "Ver": "Visitanos"}
                    </a>
                </div>
                
                <div className="card-body">
                    {empresa.logo !== undefined && empresa.logo !== "" ?   <img src={empresa.logo} className="img-fluid rounded" style={{overflow:"hidden" }}  alt={empresa.logo} />: ""}

                    {empresa.direccion !== "" ? <span className="text-dark truncate" dangerouslySetInnerHTML={{__html: `<strong>Dir:</strong> ${empresa.direccion}`}} />: ""}
                    {empresa.direccion !== "" ? <br />: ""}

                    {empresa.celular !== "" || empresa.telefono_fijo !== ""? <span className="text-dark truncate" dangerouslySetInnerHTML={{__html: `<strong>Telf:</strong> ${empresa.celular} - ${empresa.telefono_fijo}`}} />: ""}
                    {empresa.celular !== "" || empresa.telefono_fijo !== "" ? <br />: ""}

                    {empresa.email !== "" ? <span className="text-dark truncate" style={{fontSize: 14}} dangerouslySetInnerHTML={{__html: `<strong>Correo:</strong> ${empresa.email}`}} />: ""}
                    {empresa.email !== "" ? <br />: ""}

                    {empresa.descripcion !== "" && empresa.descripcion !== undefined ? <div style={{height: 70}}><span className="text-dark " style={{fontSize: 14}} dangerouslySetInnerHTML={{__html: `${empresa.descripcion}`}} /> </div>: ""}
                    {empresa.descripcion !== "" && empresa.descripcion !== undefined ? <br />: ""}
                </div>
            </div>
        </div>
    )
}

export default CardEmpresa