import React, { Component } from 'react';

import PropTypes from 'prop-types';
import { Navbar, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom';
//import Menu from '../static/js/Menu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

class Header extends Component {
    PropTypes = {
        body: PropTypes.object.isRequired
    };

    componentDidMount() {
        //Menu.enableEventMenu()
        //Menu.enableClickEventMenu ()
    }

    render = () => {
        //const { menu } = this.props;
        return <header id="header" className="fixed-top">
            <div className="container-custom">
                <Navbar variant="dark" expand="md" >
                    <h1 className="logo">
                        <Navbar.Brand href={'/'}></Navbar.Brand>
                    </h1>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav" className="nav">
                        <Nav className="mr-auto menu">
                            <div className="link-menu" >
                                <Nav.Link href={'#'} active={true} >Productos</Nav.Link>
                                <div className="sub-menu" >
                                    <div className="body-sub-menu">
                                        <ol className="list" aria-label="submenu">
                                            <li className="menu-item">
                                                <div className="link-a">
                                                    <a href="#0">Tarjeta Madre</a>
                                                </div>
                                                <ol className="sub-list"  >
                                                    <li>
                                                        <Link to={{ pathname: "https://www.gigabyte.com/Motherboard" }} target="_blank">Gigabyte</Link>
                                                    </li>
                                                    <li>
                                                        <Link to={{ pathname: "https://www.arktekco.com/products/cat/38" }} target="_blank">Arktek</Link>
                                                    </li>
                                                    <li>
                                                        <Link to={{ pathname: "https://www.msi.com/Motherboards#?tag=Intel-Z490" }} target="_blank">MSI</Link>
                                                    </li>
                                                </ol>
                                                {/*<div className="item-card" >
                                                    <a href="#" className="btn-more">
                                                        <div className="cover-img text-center" >
                                                            <img src="https://www.gskill.com/_upload/images/1904031528190.png" alt="" className="" draggable="false" />
                                                        </div>
                                                        <div className="" style={{ display: 'block' }}>
                                                            <div className="cover-title">Power Supply</div>
                                                            <div className="cover-summary text-justify">Prioritizing stability and reliability, G.SKILL PSUs are designed to efficiently supply continuous power for every single component of your system....</div>
                                                            <span className="cover-more">Leer Más</span>
                                                        </div>
                                                    </a>
                                                </div>*/}
                                            </li>
                                            <li className="menu-item">
                                                <div className="link-a">
                                                    <a href="#0">Tarjeta de vídeo</a>
                                                </div>
                                                <ol className="sub-list"  >
                                                    <li className="">
                                                        <Link to={{ pathname: "https://www.gigabyte.com/Graphics-Card" }} target="_blank">Gigabyte</Link>
                                                    </li>
                                                    <li className="">
                                                        <Link to={{ pathname: "https://www.evga.com/products/productlist.aspx?type=0" }} target="_blank">EVGA</Link>
                                                    </li>
                                                </ol>
                                            </li>
                                            <li className="menu-item">
                                                <div className="link-a">
                                                    <a href="#0">Memoria</a>
                                                </div>
                                                <ol className="sub-list"  >
                                                    <li className="">
                                                        <Link to={{ pathname: "https://www.gskill.com/products/1/165/Desktop-Memory" }} target="_blank">Gskill</Link>
                                                    </li>
                                                    <li className="">
                                                        <Link to={{ pathname: "https://www.crucial.com/products/gaming-memory" }} target="_blank">Ballistix</Link>
                                                    </li>
                                                    <li className="">
                                                        <Link to={{ pathname: "https://www.kingston.com/es/memory?memorytype=Memory%20for%20Servers,%20Desktops,%20and%20Laptops" }} target="_blank">Kingston</Link>
                                                    </li>
                                                    <li className="">
                                                        <Link to={{ pathname: "https://www.crucial.com/catalog/memory" }} target="_blank">Crucial</Link>
                                                    </li>
                                                </ol>
                                            </li>

                                            <li className="menu-item">
                                                <div className="link-a">
                                                    <a href="#0">Monitor</a>
                                                </div>
                                                <ol className="sub-list"  >
                                                    <li className="">
                                                        <Link to={{ pathname: "https://www.gigabyte.com/Monitor" }} target="_blank">Gigabyte</Link>
                                                    </li>
                                                </ol>
                                            </li>

                                            <li className="menu-item">
                                                <div className="link-a">
                                                    <a href="#0">Fuentes de Alimentación</a>
                                                </div>
                                                <ol className="sub-list"  >
                                                    <li className="">
                                                        <Link to={{ pathname: "https://www.evga.com/products/productlist.aspx?type=10" }} target="_blank">EVGA</Link>
                                                    </li>
                                                    <li className="">
                                                        <Link to={{ pathname: "https://cougargaming.com/es/products/psus/" }} target="_blank">Cougar</Link>
                                                    </li>
                                                </ol>
                                            </li>

                                            <li className="menu-item">
                                                <div className="link-a">
                                                    <a href="#0">Discos Duros</a>
                                                </div>
                                                <ol className="sub-list"  >
                                                    <li className="">
                                                        <Link to={{ pathname: "https://www.westerndigital.com/products/data-center-drives#hard-disk-hdd" }} target="_blank">Western Digital</Link>
                                                    </li>
                                                    <li className="">
                                                        <Link to={{ pathname: "https://www.seagate.com/la/es/consumer/backup/" }} target="_blank">Seagate</Link>
                                                    </li>
                                                </ol>
                                            </li>

                                            <li className="menu-item">
                                                <div className="link-a">
                                                    <a href="#0">Disco Solido</a>
                                                </div>
                                                <ol className="sub-list"  >
                                                    <li className="">
                                                        <Link to={{ pathname: "https://www.crucial.com/products/ssd/crucial-p5-ssd" }} target="_blank">Crucial</Link>
                                                    </li>
                                                    <li className="">
                                                        <Link to={{ pathname: "https://www.pny.com/consumer/view-all-products/solid-state-drives" }} target="_blank">PNY</Link>
                                                    </li>
                                                    <li className="">
                                                        <Link to={{ pathname: "https://www.lexar.com/es/products/ssd/" }} target="_blank">Lexar</Link>
                                                    </li>
                                                </ol>
                                            </li>

                                            <li className="menu-item">
                                                <div className="link-a">
                                                    <a href="#0">Gabinetes</a>
                                                </div>
                                                <ol className="sub-list"  >
                                                    <li className="">
                                                        <Link to={{ pathname: "https://cougargaming.com/es/products/cases/" }} target="_blank">Cougar</Link>
                                                    </li>
                                                </ol>
                                            </li>

                                            <li className="menu-item">
                                                <div className="link-a">
                                                    <a href="#0">Audifonos</a>
                                                </div>
                                                <ol className="sub-list">
                                                    <li className="">
                                                        <Link to={{ pathname: "https://steelseries.com/gaming-headsets" }} target="_blank">Steel Series</Link>
                                                    </li>
                                                    <li className="">
                                                        <Link to={{ pathname: "https://www.bloody.com/en/products.php?pid=12" }} target="_blank">Bloody</Link>
                                                    </li>
                                                </ol>
                                            </li>

                                            <li className="menu-item">
                                                <div className="link-a">
                                                    <a href="#0">Teclado</a>
                                                </div>
                                                <ol className="sub-list"  >
                                                    <li className="">
                                                        <Link to={{ pathname: "https://steelseries.com/gaming-keyboards" }} target="_blank">Steel Series</Link>
                                                    </li>
                                                    <li className="">
                                                        <Link to={{ pathname: "https://cougargaming.com/es/products/keyboards/" }} target="_blank">Cougar</Link>
                                                    </li>
                                                    <li className="">
                                                        <Link to={{ pathname: "https://www.bloody.com/en/products.php?pid=11" }} target="_blank">Bloody</Link>
                                                    </li>
                                                </ol>
                                            </li>

                                            <li className="menu-item">
                                                <div className="link-a">
                                                    <a href="#0">Mouse</a>
                                                </div>
                                                <ol className="sub-list"  >
                                                    <li className="">
                                                        <Link to={{ pathname: "https://steelseries.com/gaming-mousepads" }} target="_blank">Steel Series</Link>
                                                    </li>
                                                    <li className="">
                                                        <Link to={{ pathname: "https://cougargaming.com/es/products/mice/" }} target="_blank">Cougar</Link>
                                                    </li>
                                                    <li className="">
                                                        <Link to={{ pathname: "https://www.bloody.com/en/products.php?pid=10" }} target="_blank">Bloody</Link>
                                                    </li>
                                                </ol>
                                            </li>

                                            <li className="menu-item">
                                                <div className="link-a">
                                                    <a href="#0">PadMouse</a>
                                                </div>
                                                <ol className="sub-list"  >
                                                    <li className="">
                                                        <Link to={{ pathname: "https://steelseries.com/gaming-mousepads" }} target="_blank">Steel Series</Link>
                                                    </li>
                                                    <li className="">
                                                        <Link to={{ pathname: "https://www.bloody.com/en/products.php?pid=13" }} target="_blank">Bloody</Link>
                                                    </li>
                                                </ol>
                                            </li>

                                            <li className="menu-item">
                                                <div className="link-a">
                                                    <a href="#0">Silla</a>
                                                </div>
                                                <ol className="sub-list"  >
                                                    <li className="">
                                                        <Link to={{ pathname: "https://cougargaming.com/es/products/chairs/" }} target="_blank">Cougar</Link>
                                                    </li>
                                                </ol>
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>

                            <div className="link-menu" >
                                <Nav.Link href="#" active={true} >Comunidad</Nav.Link>
                                <div className="sub-menu">
                                    <div className="body-sub-menu">
                                        <ol className="list" aria-label="submenu">
                                            <li className="menu-item">
                                                <div className="link-a">
                                                    <Link to={{ pathname: "/ultimas-noticias" }} draggable="false" >Últimas Noticias</Link>
                                                </div>
                                            </li>
                                            <li className="menu-item">
                                                <div className="link-a">
                                                    <Link to={{ pathname: "/review" }} draggable="false" >Vídeos</Link>
                                                </div>
                                            </li>
                                            {/*<li className="menu-item">
                                                <div className="link-a">
                                                    <a href="#0">Eventos</a>
                                                </div>
                                            </li>
                                            <li className="menu-item">
                                                <div className="link-a">
                                                    <a href="#0">Sorteos</a>
                                                </div>
                                            </li>
                                            <li className="menu-item">
                                                <div className="link-a">
                                                    <a href="#0">Esports</a>
                                                </div>
                                            </li>*/}
                                        </ol>
                                    </div>
                                </div>
                            </div>

                            <div className="link-menu">
                                <Nav.Link href={'#'} active={true}>Apoyo</Nav.Link>
                                <div className="sub-menu">
                                    <div className="body-sub-menu">
                                        <ol className="list" aria-label="submenu">

                                            <li className="menu-item">
                                                <div className="link-a">
                                                    <a href="#0">Soporte Técnico</a>
                                                </div>
                                                <ol className="sub-list" >
                                                    <li className="">
                                                        <Link to={{ pathname: "/politicas-garantia" }} draggable="false" >Políticas de Garantía</Link>
                                                    </li>
                                                    <li className="">
                                                        <Link to={{ pathname: "/rma" }} draggable="false" >RMA</Link>
                                                    </li>
                                                </ol>
                                            </li>
                                            <li className="menu-item">
                                                <div className="link-a">
                                                    <Link to={{ pathname: "/infografia" }} draggable="false" >Infografías</Link>
                                                </div>
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>

                            <div className="link-menu">
                                <Nav.Link href="#" active={true}>Donde Comprar</Nav.Link>
                                <div className="sub-menu">
                                    <div className="body-sub-menu">
                                        <ol className="list" aria-label="submenu">
                                            {/*<li className="menu-item">
                                                <div className="link-a">
                                                    <a href="#0" >Compra en línea</a>
                                                </div>
                                            </li>*/}
                                            <li className="menu-item">
                                                <div className="link-a">
                                                    <Link to={{ pathname: "/donde-comprar" }} draggable="false" >
                                                        Encuentra una tienda cerca de tí
                                                    </Link>
                                                </div>
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>

                            <div className="link-menu">
                                <Nav.Link href="#" active={true}>Sobre Nosotros</Nav.Link>
                                <div className="sub-menu">
                                    <div className="body-sub-menu">
                                        <ol className="list" aria-label="submenu">
                                            <li className="menu-item">
                                                <div className="link-a">
                                                    <Link to={{ pathname: "/perfil" }} draggable="false" >
                                                        Perfil
                                                    </Link>
                                                </div>
                                            </li>
                                            <li className="menu-item">
                                                <div className="link-a">
                                                    <Link to={{ pathname: "/socios" }} draggable="false">
                                                        Socios
                                                    </Link>
                                                </div>
                                            </li>
                                            <li className="menu-item">
                                                <div className="link-a">
                                                    <Link to={{ pathname: "/contactanos" }} draggable="false">
                                                        Contactanos
                                                    </Link>
                                                </div>
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </Nav>
                        <section className="htmleaf-container" style={{ border: '1px solid red' }}>

                            <form className="form-inline  searchBox">
                                <div className="search-wrapper">
                                    <div className="input-holder text-right ">
                                        <button className="search-icon" >
                                            <i className="uil uil-search-alt"></i>
                                        </button>
                                    </div>
                                    <span className="close" >
                                    </span>
                                    <div className="result-container"></div>
                                </div>
                            </form>

                            <div className="searchBox">
                                <ul className=" follow-header" >
                                    <li>
                                        <Link to={{ pathname: "https://www.facebook.com/Onecompany.empresa" }} className="icon  " target="_blank" draggable="false">
                                            <FontAwesomeIcon icon={['fab', 'facebook']} size="1x" />
                                        </Link>
                                    </li>
                                    <li >
                                        <Link to={{ pathname: "https://www.facebook.com/Onecompany.empresa" }} className="icon  " target="_blank" draggable="false">
                                            <FontAwesomeIcon icon={['fab', 'youtube']} size="1x" />
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to={{ pathname: "https://www.facebook.com/Onecompany.empresa" }} className="icon  " target="_blank" draggable="false">
                                            <FontAwesomeIcon icon={['fab', 'twitter']} size="1x" />
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </section>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        </header>
            ;
    }
}

export default Header;