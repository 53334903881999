import React, { Component } from 'react';
import CardEmpresa from '../component/CardEmpresa'

var empresa = [{
    "id": 1,
    "name": "hardwaresfera.com",
    "code": "",
    "propietario": "",
    "pagina_web": "https://hardwaresfera.com/noticias/hardware/rendimiento-nvidia-rtx-3070-3dmark/",
    "email": "",
    "logo": "https://hardwaresfera.com/wp-content/uploads/2020/10/nvidia-rtx-3070-rendimiento-portada-780x470.jpg",
    "celular": "",
    "telefono_fijo": "",
    "direccion": "",
    "descripcion": "NVIDIA RTX 3070 ofrece un buen rendimiento en el software 3DMark"
},
{
    "id": 2,
    "name": "hardzone.es",
    "code": "",
    "propietario": "",
    "pagina_web": "https://www.xataka.com/componentes/microarquitectura-zen-3-ryzen-5000-amd-explicada-asi-como-estas-cpu-quieren-asaltar-pc-sobremesa",
    "email": "",
    "logo": "https://i.blogs.es/d4d7ca/amdzen3ap/450_1000.jpg",
    "celular": "",
    "telefono_fijo": "",
    "direccion": "",
    "descripcion": "La microarquitectura Zen 3 de los Ryzen 5000 de AMD, explicada: así es como estas CPU quieren asaltar los PC de sobremesa"
},
{
    "id": 3,
    "name": "hardzone.es",
    "code": "",
    "propietario": "",
    "pagina_web": "https://hardzone.es/marcas/intel/",
    "email": "",
    "logo": "https://hardzone.es/app/uploads-hardzone.es/2019/10/Intel-Tremont.jpg",
    "celular": "",
    "telefono_fijo": "",
    "direccion": "",
    "descripcion": "Así puedes actualizar los drivers de tu gráfica integrada de Intel"
},
{
    "id": 4,
    "name": "hardzone.es",
    "code": "",
    "propietario": "",
    "pagina_web": "https://hardzone.es/marcas/intel/comparativa-chipsets-intel-lga-1200/",
    "email": "",
    "logo": "https://hardzone.es/app/uploads-hardzone.es/2020/08/comparativa-chipsets-Intel-1268x664-1.jpg",
    "celular": "",
    "telefono_fijo": "",
    "direccion": "",
    "descripcion": "Comparativa de chipsets Intel para socket LGA1200, ¿cuál necesitas?"
},
{
    "id": 5,
    "name": "hardzone.es",
    "code": "",
    "propietario": "",
    "pagina_web": "https://hardzone.es/noticias/componentes/phison-e18-controladora-ssd/",
    "email": "",
    "logo": "https://hardzone.es/app/uploads-hardzone.es/2020/10/Phison.jpg",
    "celular": "",
    "telefono_fijo": "",
    "direccion": "",
    "descripcion": "Phison prepara una controladora SSD que llevará al límite el PCIe 4.0 x4"
},
{
    "id": 6,
    "name": "hardzone.es",
    "code": "",
    "propietario": "",
    "pagina_web": "https://hardzone.es/noticias/tarjetas-graficas/mall-amd-big-navi/",
    "email": "",
    "logo": "https://hardzone.es/app/uploads-hardzone.es/2020/01/AMD-Radeon-Big-Navi-GPU-Feature.jpg",
    "celular": "",
    "telefono_fijo": "",
    "direccion": "",
    "descripcion": "¿Qué es MALL? La nueva característica de las GPUs AMD Big Navi"
} ]

class LastNews extends Component {

    constructor() {
        super();
        this.Crawler = require("crawler");
        this.state = {
            listHtmlObject: []
        }
    }

    async componentDidMount() {
        this.redirectToPage = this.redirectToPage.bind(this)
    }

    redirectToPage(event, url) {
        event.preventDefault()
        window.open(url, '_blank');
    }

    render = () => {
        //const { menu } = this.props;
        return <div className="container mt-8 mb-8">
            <section className="perfil pt-3 text-white">
                <div className="row pb-3"  >
                    <div className="col-12" style={{ borderBottom: '1px solid rgb(58, 58, 58)' }}>
                        <h3 className="heading text-left">Ultimas Noticias</h3>
                    </div>
                </div>

                <div className="row pt-3">
                    <CardEmpresa empresa={empresa[0]}/>
                    <CardEmpresa empresa={empresa[1]}/>
                    <CardEmpresa empresa={empresa[2]}/>
                    <CardEmpresa empresa={empresa[3]}/>
                    <CardEmpresa empresa={empresa[4]}/>
                    <CardEmpresa empresa={empresa[5]}/>
                </div>
            </section>
        </div>
    }
}

export default LastNews;