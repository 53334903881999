import React, { useState, useEffect, createRef, useRef } from 'react';

import Image from 'react-bootstrap/Image'

const CardProduct = (props) => {

    const refDivImage = createRef();
    const refIframe = useRef()

    const [showVideo, setShowVideo] = useState(false)
    const [heightVideo, setHeightVideo] = useState(350)
    const { title, src, srcVideo, boxShadow } = props
    let _boxShadow = boxShadow || false
    useEffect(() => {
        let iframe = refIframe.current
        if(Boolean(iframe)){
            refIframe.current.setAttribute("height", heightVideo)
        }
        
           
    }, [heightVideo]);

    const handleClickVideo = (event) => {
        event.preventDefault();
        let height = refDivImage.current.getBoundingClientRect().height
        setHeightVideo( Math.round(height))
        setShowVideo(true)
    }

    return (
        <div className="cart-product">

            {!showVideo  && (srcVideo !== undefined && srcVideo !== "") ?
                <div className="title-video-container" onClick={handleClickVideo}>
                    <h3 className="title-video" >{title}</h3>
                    <p className="btn-video"></p>
                </div>
                : ""}

            {(srcVideo === undefined || srcVideo === "") ?
                <div className="title-container">
                    <span className="title">{title}</span>
                </div>
                : ""}

            {!showVideo ?
                <div className={srcVideo !== undefined && srcVideo !== "" ? "" : "image-container"} ref={refDivImage} >
                    {_boxShadow ? 
                    <div className="box-shadow"></div>
                    : ""
                    }
                    <Image src={src} fluid />
                </div> : ""}

            {showVideo && (srcVideo !== undefined && srcVideo !== "") ?
                <div className="video-container home-mv-embedded" onClick={handleClickVideo} >
                    <iframe width="100%" height="315" src={srcVideo}
                        frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                        allowFullScreen ref={refIframe} title="video youtube"></iframe>
                </div>
                : ""}
        </div>
    )
}

export default CardProduct