import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'

//import '@fortawesome/fontawesome-svg-core/styles.css'
//config.autoAddCss = false
//<i class="fas fa-arrow-circle-down"></i>
import {
    faUser,
    faEnvelope,
    faLock,
    faEye,
    faEyeSlash,
    faArrowUp,
    faArrowDown,
    faExpand,
    faCompress,
    faBolt,
    faSpinner,
    faPhone,
    faMobile,
    faMobileAlt,
    faCheck,
    faTimes
} from '@fortawesome/free-solid-svg-icons'

import {
    faFacebookSquare,
    faTwitter,
    faLinkedin,
    faSkype,
    faGoogle,
    faInstagramSquare,
    faYoutube,
} from '@fortawesome/free-brands-svg-icons'

/*import {
    far,
} from '@fortawesome/free-regular-svg-icons'
*/
library.add(
    fab,
    //far,
    faUser,
    faEnvelope,
    faLock,
    faEye,
    faEyeSlash,
    faArrowUp,
    faArrowDown,
    faExpand,
    faCompress,
    faBolt,
    faSpinner,
    faPhone,
    faMobile,
    faMobileAlt,
    faCheck,
    faTimes,
    faFacebookSquare,
    faTwitter,
    faLinkedin,
    faSkype,
    faGoogle,
    faInstagramSquare,
    faYoutube
)
