import React from 'react';
import { Route, Switch } from 'react-router-dom';

//Components
import App from './Components/App';
import Home from './Components/Home';
import Page404 from './Components/Page404';

import Perfil from './Components/Pages/Perfil';
import Contactanos from './Components/Pages/Contactanos';
import Socios from './Components/Pages/Socios';
import Rma from './Components/Pages/Rma';
import Warranty from './Components/Pages/Warranty';
import Infografia from './Components/Pages/Infografia';
import LastNews from './Components/Pages/LastNews';
import Review from './Components/Pages/Review';
import WhereToBuy from './Components/Pages/WhereToBuy';

const AppRoutes = () =>
    <App>
        <Switch>
            <Route exact path="/perfil" component={Perfil} />
            <Route exact path="/contactanos" component={Contactanos} />
            <Route exact path="/socios" component={Socios} />
            <Route exact path="/rma" component={Rma} />
            <Route exact path="/politicas-garantia" component={Warranty} />
            <Route exact path="/infografia" component={Infografia} />
            <Route exact path="/ultimas-noticias" component={LastNews} />
            <Route exact path="/review" component={Review} />
            <Route exact path="/donde-comprar" component={WhereToBuy} />
            <Route exact path="/" component={Home} />

            <Route exact path="/page-404" component={Page404} />

            <Route component={Page404} />
        </Switch>
    </App>;

export default AppRoutes;