import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Warranty extends Component {
    PropTypes = {
        body: PropTypes.object.isRequired
    };

    componentDidMount() {}

    render = () => {
        //const { menu } = this.props;
        return <div className="container mt-8 mb-8">
            <section className="perfil pt-3 text-white">
                <div className="row pb-3"  >
                    <div className="col-12" style={{ borderBottom: '1px solid rgb(58, 58, 58)' }}>
                        <h3 className="heading text-left">Política de garantía Onecompany</h3>
                    </div>
                </div>

                <div className="row pt-3" >
                    <div className="col-12 col-md-12 ">
                        <p className="pl-3">Esta política de devolución se aplica a los productos de Onecompany:</p>
                        <p className="pl-3">A menos que se detalle en otro acuerdo contractual, los siguientes puntos se aplican a todas las devoluciones de los clientes:</p>

                        <ul className="ml-3">
                            <li className="text-justify">Deberá pagar los gastos de todos los envíos que realice hasta la oficina de soporte de Onecompany.</li>
                            <li className="text-justify">Envíe su producto a Onecompany utilizando la empresa de transporte de su elección. Le recomendamos que utilice un método con el que se pueda realizar el seguimiento del envío. Onecompany no se responsabiliza de los envíos en los que el transportista no proporcione el recibo de la entrega.</li>
                            <li className="text-justify">Si sus productos están bajo garantía, Onecompany pagará el franqueo de los productos previamente revisados/reemplazados por Onecompany a usted.</li>
                            <li className="text-justify">Onecompany no es responsable por la pérdida o daños de artículos en tránsito. Sugerimos que suscriba un seguro de transporte para sus envíos.</li>
                            <li className="text-justify">Los envíos que lleguen a la oficina de Onecompany con cargos adeudados se rechazarán.</li>
                            <li className="text-justify">El cliente es responsable de guardar o realizar copias de seguridad de los datos contenidos en cualquier producto devuelto. ONECOMPANY NO SE RESPONSABILIZARÁ DE DICHOS DATOS Y NO ASUMIRÁ NINGUNA RESPONSABILIDAD DERIVADA DE LOS DAÑOS O LA PÉRDIDA DE TALES DATOS MIENTRAS EL PRODUCTO ESTÉ EN POSESIÓN DE ONECOMPANY.</li>
                        </ul>
                    </div>
                </div>

                <div className="row" >
                    <div className="col-12 col-md-12 pt-4">
                        <h3 className="title-red">Aviso de reemplazo de productos</h3>
                    </div>
                </div>

                <div className="row pt-3" >
                    <div className="col-12 col-md-12 ">
                        <ul className="ml-3">
                            <li className="">Los productos de reemplazo se enviarán en los tiempos establecidos según existencias en el inventario de Onecompany y con previo aviso.</li>
                            <li className="">Cualquier servicio de recuperación de datos requerido se debe realizar ANTES de solicitar una RMA de Onecompany.</li>
                        </ul>

                        <p className="pl-3 text-justify">EL NÚMERO DE LA RMA (RETURN MATERIAL AUTHORIZATION) DE ONECOMPANY QUE SE EMITE ES VÁLIDO SÓLO POR LA 
                        <strong>DEVOLUCIÓN DE UN PRODUCTO QUE NO CUMPLE CON LAS ESPECIFICACIONES Y QUE ESTÁ BAJO GARANTÍA</strong>. </p>
                    </div>
                </div>

                <div className="row" >
                    <div className="col-12 col-md-12 pt-4">
                        <h3 className="title-red">CONDICIONES DE RMA:</h3>
                    </div>
                </div>

                <div className="row pt-3" >
                    <div className="col-12 col-md-12 ">
                        <p className="pl-3 text-justify">Usted acepta no enviar productos que no estén relacionados con un reclamo de garantía. 
                        Por ejemplo, <span className="text-purple-light">USTED NO DEBE ENVIARNOS A TRAVÉS DE ESTE PROCESO DE RMA NINGÚN PRODUCTO QUE SEA FALSIFICADO</span>, productos cuya cobertura de 
                        garantía se haya vencido o nunca haya existido (usualmente la evidencia de esto son etiquetas retiradas, 
                        dañadas o modificadas), productos que tengan sólo defectos no cubiertos por la garantía <span className="text-purple-light">(por ejemplo, la mayoría de nuestras 
                        declaraciones de garantía no cubren problemas causados por accidentes, abuso, negligencia, impactos, descargas electrostáticas, 
                        calor o humedad, instalación, operación, mantenimiento o modificación incorrectos, por otros equipos o contraseñas 
                        perdidas o por cualquier uso incorrecto contrario a las instrucciones que contiene el manual de usuario)</span>. Productos 
                        que se haya determinado que están en estado (Not Trouble Found, Sin problemas) no son elegibles para el servicio de garantía.  
                        Si nos envía productos que no están cubiertos por un reclamo justificado de garantía utilizando este proceso RMA.</p>
                    </div>
                </div>

                <div className="row pt-3" >
                    <div className="col-12 col-md-12 ">
                        <p className="pl-3">Última actualización: 20 de octubre de 2020</p>
                    </div>
                </div>
                
            </section>
        </div>
    }
}

export default Warranty;