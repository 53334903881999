import React, { Component } from 'react';

class Socios extends Component {

    constructor() {
        super();
        this.Crawler = require("crawler");
    }

    componentDidMount() {
    }

    render = () => {
        //const { menu } = this.props;
        return <div className="container mt-8 mb-8">
            <section className="perfil pt-3 text-white">
                <div className="row pb-3" >
                    <div className="col-12">
                        <h3 className="heading text-left">Socios</h3>
                    </div>
                </div>
                
                <div className="row" >
                    <div className="col-12 col-md-4 pt-4">
                        <a href="https://www.aorus.com/" target="_blank" rel="noopener noreferrer">
                            <img className="img-fluid rounded" src="/static/img/partner/aorus.jpg" alt="aorus" />
                        </a>
                    </div>

                    <div className="col-12 col-md-4 pt-4">
                        <a href="https://www.gigabyte.com/" target="_blank" rel="noopener noreferrer" >
                            <img className="img-fluid rounded" src="/static/img/partner/gigabyte.jpg" alt="gigabyte" />
                        </a>
                    </div>

                    <div className="col-12 col-md-4 pt-4">
                        <a href="https://www.evga.com/" target="_blank" rel="noopener noreferrer" >
                            <img className="img-fluid rounded" src="/static/img/partner/evga.jpg" alt="evga" />
                        </a>
                    </div>
                </div>

                <div className="row " >
                    <div className="col-12 col-md-4 pt-4">
                        <a href="https://www.westerndigital.com/" target="_blank" rel="noopener noreferrer">
                            <img className="img-fluid rounded" src="/static/img/partner/westerndigital.jpg" alt="westerndigital" />
                        </a>
                    </div>

                    <div className="col-12 col-md-4 pt-4">
                        <a href="https://www.msi.com/index.php" target="_blank" rel="noopener noreferrer">
                            <img className="img-fluid rounded" src="/static/img/partner/msi.jpg" alt="msi"/>
                        </a>
                    </div>

                    <div className="col-12 col-md-4 pt-4">
                        <a href="https://www.gskill.com/" target="_blank" rel="noopener noreferrer">
                            <img className="img-fluid rounded" src="/static/img/partner/gskill.jpg" alt="gskill" />
                        </a>
                    </div>
                </div>

                <div className="row " >
                    <div className="col-12 col-md-4 pt-4">
                        <a href="https://steelseries.com/" target="_blank" rel="noopener noreferrer">
                            <img className="img-fluid rounded" src="/static/img/partner/steelseries.jpg" alt="steelseries"/>
                        </a>
                    </div>

                    <div className="col-12 col-md-4 pt-4">
                        <a href="https://www.crucial.com/" target="_blank" rel="noopener noreferrer">
                            <img className="img-fluid rounded" src="/static/img/partner/crucial.jpg" alt="crucial"/>
                        </a>
                    </div>

                    <div className="col-12 col-md-4 pt-4">
                    </div>
                </div>
            </section>
        </div>
    }
}

export default Socios;