import React, { useState, useEffect, createRef } from 'react';
import Image from 'react-bootstrap/Image'
//https://react-bootstrap.github.io/components/carousel/
import Carousel from 'react-bootstrap/Carousel'

const CarroucelProduct = (props) => {

    const refNexIcon = createRef()
    const refPrevIcon = createRef()

    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    useEffect(() => {
        refNexIcon.current.parentElement.style.display = 'none'
        refPrevIcon.current.parentElement.style.display = 'none'
    }, [index]);

    return (
        <div className="container-custom   " >
            <Carousel activeIndex={index} onSelect={handleSelect} indicators={true} autoPlay={true} 
            prevIcon={<span aria-hidden="true" className="carousel-control-prev-icon" ref={refPrevIcon} />}
            nextIcon={<span aria-hidden="true" className="carousel-control-next-icon " ref={refNexIcon} />} 
             >
                <Carousel.Item className="mt-1" >
                    <div className="row">
                        <div className="col-6 card-carroucel "  >
                            <div className="img-carroucel-fluid " >
                                <Image src={'/static/img/product_carroucel/prod1.jpg'} fluid />
                            </div>
                            <div className="box-shadow"></div>
                        </div>
                        <div className="col-6  card-carroucel " >
                            <div className="img-carroucel-fluid " >
                                <Image src={'/static/img/product_carroucel/prod2.jpg'} fluid />
                            </div>
                            <div className="box-shadow"></div>
                        </div>
                    </div>

                    <div className="row ">
                        <div className="col-6 card-carroucel" >
                            <div className="img-carroucel-fluid" >
                                <Image src={'/static/img/product_carroucel/prod3.jpg'} fluid />
                            </div>
                            <div className="box-shadow"></div>
                        </div>
                        <div className="col-6 card-carroucel" >
                            <div className="img-carroucel-fluid" >
                                <Image src={'/static/img/product_carroucel/prod4.jpg'} fluid />
                            </div>
                            <div className="box-shadow"></div>
                        </div>
                    </div>
                </Carousel.Item>
                 <Carousel.Item className="mt-1" >
                    <div className="row ">
                        <div className="col-6 card-carroucel" >
                            <div className="img-carroucel-fluid" >
                                <Image src={'/static/img/product_carroucel/prod5.jpg'} fluid />
                            </div>
                            <div className="box-shadow"></div>
                        </div>
                        <div className="col-6 card-carroucel" >
                            <div className="img-carroucel-fluid" >
                                <Image src={'/static/img/product_carroucel/prod6.jpg'} fluid />
                            </div>
                            <div className="box-shadow"></div>
                        </div>
                    </div>

                    <div className="row ">
                        <div className="col-6 card-carroucel" >
                            <div className="img-carroucel-fluid" >
                                <Image src={'/static/img/product_carroucel/prod7.jpg'} fluid />
                            </div>
                            <div className="box-shadow"></div>
                        </div>
                        <div className="col-6 card-carroucel" >
                            <div className="img-carroucel-fluid" >
                                <Image src={'/static/img/product_carroucel/prod8.jpg'} fluid />
                            </div>
                            <div className="box-shadow"></div>
                        </div>
                    </div>
                </Carousel.Item>
                <Carousel.Item className="mt-1">
                    <div className="row ">
                        <div className="col-6 card-carroucel" >
                            <div className="img-carroucel-fluid" >
                                <Image src={'/static/img/product_carroucel/prod9.jpg'} fluid />
                            </div>
                            <div className="box-shadow"></div>
                        </div>
                        <div className="col-6  card-carroucel" >
                            <div className="img-carroucel-fluid " >
                                <Image src={'/static/img/product_carroucel/prod10.jpg'} fluid />
                            </div>
                            <div className="box-shadow"></div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-6 card-carroucel" >
                            <div className="img-carroucel-fluid" >
                                <Image src={'/static/img/product_carroucel/prod11.jpg'} fluid />
                            </div>
                            <div className="box-shadow"></div>
                        </div>
                        <div className="col-6 card-carroucel" >
                            <div className="img-carroucel-fluid" >
                                <Image src={'/static/img/product_carroucel/prod12.jpg'} fluid />
                            </div>
                            <div className="box-shadow"></div>
                        </div>
                    </div>
                </Carousel.Item>
            </Carousel>
        </div>
    )
}

export default CarroucelProduct